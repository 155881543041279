export default [
	{
		title: 'Статистики',
		route: 'statistics',
		icon: 'PieChartIcon',
		resource: 'Admin',
	},
	{
		title: 'Запросы',
		route: 'queries',
		icon: 'ListIcon',
		resource: 'Admin',
	},
	{
		title: 'Пользователи системы',
		route: 'users',
		icon: 'UserIcon',
		resource: 'Admin',
	},
	{
		title: 'One to N',
		route: 'oneton',
		icon: 'UserIcon',
		resource: 'Admin',
	},
	{
		title: 'База 1:N',
		route: 'db-oneton',
		icon: 'DatabaseIcon',
		resource: 'Admin',
	},
	{
		title: 'Настройки',
		route: 'settings',
		icon: 'SettingsIcon',
		resource: 'Admin',
	},
	{
		title: 'Демо',
		route: 'demo',
		icon: 'SettingsIcon',
		resource: 'Admin',
	},
]
